import React from 'react';
import { Link } from 'react-router-dom';

const SongBookItem = ({ songName, songFormat }) => {
    const saveScrollPosition = () => {
        localStorage.setItem('songBookScrollYPosition', window.scrollY);
    }

    return (
    <li>
        <Link to={"/song/" + songName + "." + songFormat} onClick={saveScrollPosition}>{songName}</Link>
    </li>);
}

export default SongBookItem;