import React, { useState } from 'react';
import './App.css';
import SongBook from './songbook/SongBook'
import AddSong from './AddSong'
import SongPage from './song/SongPage'
import EditSong from './EditSong'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink
} from "react-router-dom";
import CurrentSong from './CurrentSong';
import { ThemeContext, themes } from './ThemeContext';

function App() {
  const getInitialThemeName = () => {
    const savedThemeName = localStorage.getItem("theme");
    if (themes[savedThemeName]) {
      return themes[savedThemeName].className;
    }
    return themes.light.className;
  }
  const [themeName, setThemeName] = useState(getInitialThemeName());

  const toggleTheme = () => {
    var newThemeName;
    if (themeName === themes.light.className) {
      newThemeName = (themes.dark.className);
    } else {
      newThemeName = themes.light.className;
    }
    setThemeName(newThemeName);
    localStorage.setItem('theme', newThemeName);
  }

  return (
    <ThemeContext.Provider value={{ theme: themes[themeName], toggleTheme }}>
      <Router>
        <div className={themes[themeName].className + " body"}>
          <nav className={"menu"}>
            <ul>
              <li>
                <NavLink end to="/">Songs</NavLink>
              </li>
              <li>
                <NavLink to="/add-song">Add</NavLink>
              </li>
              <li>
                <NavLink to="/current-song">Ongoing</NavLink>
              </li>
            </ul>
          </nav>
          <Routes>
            <Route path="/add-song" element={<AddSong />}/>
            <Route path="/edit-song/:editedSongName" element={<EditSong />}/>
            <Route path="/current-song" element={<CurrentSong />}/>
            <Route path="/song/:songName" element={<SongPage />}/>
            <Route path="/" element={<SongBook />}/>
          </Routes>
        </div>
      </Router>
    </ThemeContext.Provider>
  );
}

export default App;
