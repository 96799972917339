import NoSleep from "nosleep.js";

const noSleep = new NoSleep();

const enableWakeLock = (event) => {
    noSleep.enable();
    document.getElementById("root").removeEventListener("click", enableWakeLock);
}

const preventScreenLock = () => {
    document.getElementById("root").addEventListener("click", enableWakeLock);
}

export default preventScreenLock;