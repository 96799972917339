import axios from "axios";

function preloadSongs() {
  axios.get("/api/v1/songs")
    .then((response) => {
      const precacheLinks = response.data.songs.map(song => `/api/v1/chords/${song.name}.${song.type}`);
      caches.open("songs").then((cache) => precacheLinks.forEach(link => cache.add(link)
      .catch(error => console.error(error))))
    });
}

export default preloadSongs;